<template>
    <div class="dataAnalysis-container">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-student">
            <el-breadcrumb-item>数据分析</el-breadcrumb-item>
            <el-breadcrumb-item>创建数据</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="dataAnalysis-wrapper">
            <div class="tab-wrapper">
                <router-link to="/student/dataAnalysis/index" class="tab-item">数据管理</router-link>
                <router-link to="/student/dataAnalysis/create" class="tab-item current">创建数据</router-link>
            </div>
            <el-form :model="createForm" :rules="createRules" ref="createForm" label-position="top" style="margin-top: 30px" class="form-custom">
                <el-form-item label="数据分析标题" prop="title">
                    <el-input v-model="createForm.title" placeholder="请输入标题（3-20字）" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item>
                    <template slot="label">
                        <div class="form-item-title" v-if="!createForm.portrayal_analyse.isEdit">
                            <span class="label-text">{{createForm.portrayal_analyse.title}}</span>
                            <i class="iconfont el-icon-edit-outline" @click="showEdit(1)"></i>
                        </div>
                        <el-input v-model="createForm.portrayal_analyse.title"
                                  v-if="createForm.portrayal_analyse.isEdit"
                                  @blur="showEdit(1)"
                                  @keyup.enter.native="$event.target.blur"
                                  placeholder="请编辑">
                        </el-input>
                    </template>
                    <tiny-editor id="tiny-item1" :init="tiny_init" v-model="createForm.portrayal_analyse.content"></tiny-editor>
                </el-form-item>
                <el-form-item>
                    <template slot="label">
                        <div class="form-item-title" v-if="!createForm.account_analyse.isEdit">
                            <span class="label-text">{{createForm.account_analyse.title}}</span>
                            <i class="iconfont el-icon-edit-outline" @click="showEdit(2)"></i>
                        </div>
                        <el-input v-model="createForm.account_analyse.title"
                                  v-if="createForm.account_analyse.isEdit"
                                  @blur="showEdit(2)"
                                  @keyup.enter.native="$event.target.blur"
                                  placeholder="请编辑">
                        </el-input>
                    </template>
                    <tiny-editor id="tiny-item2" :init="tiny_init" v-model="createForm.account_analyse.content"></tiny-editor>
                </el-form-item>
                <el-form-item>
                    <template slot="label">
                        <div class="form-item-title" v-if="!createForm.selection_analyse.isEdit">
                            <span class="label-text">{{createForm.selection_analyse.title}}</span>
                            <i class="iconfont el-icon-edit-outline" @click="showEdit(3)"></i>
                        </div>
                        <el-input v-model="createForm.selection_analyse.title"
                                  v-if="createForm.selection_analyse.isEdit"
                                  @blur="showEdit(3)"
                                  @keyup.enter.native="$event.target.blur"
                                  placeholder="请编辑">
                        </el-input>
                    </template>
                    <tiny-editor id="tiny-item3" :init="tiny_init" v-model="createForm.selection_analyse.content"></tiny-editor>
                </el-form-item>
                <el-form-item>
                    <template slot="label">
                        <div class="form-item-title" v-if="!createForm.video_analyse.isEdit">
                            <span class="label-text">{{createForm.video_analyse.title}}</span>
                            <i class="iconfont el-icon-edit-outline" @click="showEdit(4)"></i>
                        </div>
                        <el-input v-model="createForm.video_analyse.title"
                                  v-if="createForm.video_analyse.isEdit"
                                  @blur="showEdit(4)"
                                  @keyup.enter.native="$event.target.blur"
                                  placeholder="请编辑">
                        </el-input>
                    </template>
                    <tiny-editor id="tiny-item4" :init="tiny_init" v-model="createForm.video_analyse.content"></tiny-editor>
                </el-form-item>
                <el-form-item style="text-align: center; margin-top: 40px">
                    <el-button size="small" class="blue-btn" @click="submitForm('createForm')">保存</el-button>
                    <el-button size="small" @click="goBack">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Editor from "@tinymce/tinymce-vue"
    export default {
        components: {
            'tiny-editor': Editor
        },
        data() {
            return {
                createForm: {
                    id: Number(this.$route.query.id) || '',
                    title: '',
                    portrayal_analyse: {
                        title: '人群画像分析',
                        content: '',
                        isEdit: false,
                    },
                    account_analyse: {
                        title: '账号分析',
                        content: '',
                        isEdit: false,
                    },
                    selection_analyse: {
                        title: '选品分析',
                        content: '',
                        isEdit: false,
                    },
                    video_analyse: {
                        title: '视频分析',
                        content: '',
                        isEdit: false,
                    },
                },
                createRules: {
                    title: [
                        { required: true, message: '请输入数据分析标题', trigger: 'blur' },
                        { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                    ],
                    // portrayal_analyse: [
                    //     { message: '请输入人群画像分析', trigger: 'blur' }
                    // ],
                    // account_analyse: [
                    //     { message: '请输入账号分析', trigger: 'blur' }
                    // ],
                    // selection_analyse: [
                    //     { message: '请输入选品分析', trigger: 'blur' }
                    // ],
                    // video_analyse: [
                    //     { message: '请输入视频分析', trigger: 'blur' }
                    // ],
                },
                //富文本框设置
                tiny_init: {
                    menubar: false,
                    // toolbar: "formatselect bold underline link table image",
                    // plugins: "link table image",

                    toolbar_drawer: "sliding",

                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    language: 'zh_CN', //本地化设置
                    height: 700,

                    //上传图片
                    images_upload_handler: function (blobInfo, success,failure,progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', 'analyse/uploadAnalyseImg');//后端给的上传图片接口
                        xhr.setRequestHeader("Authorization", localStorage.getItem('studentToken'));

                        xhr.upload.onprogress = function(e){
                            progress(e.loaded / e.total * 100);
                        }

                        xhr.onload = function() {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, { remove: true });
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300 ) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };

                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }

                        formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    }
                },
            }
        },
        mounted() {
            if (this.createForm.id) {
                this.getSingleAnalysis()
            }
        },
        methods: {
            // 获取单个数据信息
            getSingleAnalysis() {
                let param = {
                    id: this.createForm.id
                }
                this.$httpStudent.axiosGetBy(this.$api.analyse_getAnalyseDetail, param, (res) => {
                    if (res.code === 200) {
                        // this.createForm = res.data[0]
                        let tmp = {
                            title: res.data[0].title,
                        }
                        if (res.data[0].portrayal_analyse) {
                            tmp.portrayal_analyse = JSON.parse(res.data[0].portrayal_analyse)
                        } else {
                            tmp.portrayal_analyse = {
                                title: '人群画像分析',
                                content: '',
                                isEdit: false,
                            }
                        }
                        if (res.data[0].account_analyse) {
                            tmp.account_analyse = JSON.parse(res.data[0].account_analyse)
                        } else {
                            tmp.account_analyse = {
                                title: '账号分析',
                                content: '',
                                isEdit: false,
                            }
                        }
                        if (res.data[0].selection_analyse) {
                            tmp.selection_analyse = JSON.parse(res.data[0].selection_analyse)
                        } else {
                            tmp.selection_analyse = {
                                title: '选品分析',
                                content: '',
                                isEdit: false,
                            }
                        }
                        if (res.data[0].video_analyse) {
                            tmp.video_analyse = JSON.parse(res.data[0].video_analyse)
                        } else {
                            tmp.video_analyse = {
                                title: '视频分析',
                                content: '',
                                isEdit: false,
                            }
                        }
                        this.createForm = {...this.createForm, ...tmp}
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 保存
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (!this.createForm['portrayal_analyse'].content
                            && !this.createForm['account_analyse'].content
                            && !this.createForm['selection_analyse'].content
                            && !this.createForm['video_analyse'].content) {
                            return this.$message.warning('请至少输入一个内容')
                        }
                        // for (const key in this.createForm) {
                        //     if (key === 'portrayal_analyse' || key === 'account_analyse' || key === 'selection_analyse' || key === 'video_analyse') {
                        //         delete this.createForm[key].isEdit
                        //     }
                        // }

                        let formData = new FormData();
                        formData.append('title', this.createForm.title)
                        if (this.createForm.portrayal_analyse.content) {
                            formData.append('portrayal_analyse', JSON.stringify(this.createForm.portrayal_analyse))
                        }
                        if (this.createForm.account_analyse.content) {
                            formData.append('account_analyse', JSON.stringify(this.createForm.account_analyse))
                        }
                        if (this.createForm.selection_analyse.content) {
                            formData.append('selection_analyse', JSON.stringify(this.createForm.selection_analyse))
                        }
                        if (this.createForm.video_analyse.content) {
                            formData.append('video_analyse', JSON.stringify(this.createForm.video_analyse))
                        }
                        if (this.createForm.id) {
                            formData.append('id', this.createForm.id)
                        }
                        this.$httpStudent.axiosPost(this.$api.analyse_saveDataAnalyse, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message)
                                this.$router.push('/student/dataAnalysis/index')
                            } else {
                                this.$message.warning(res.message)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 返回
            goBack() {
                this.$router.go(-1)
            },
            // 显示隐藏编辑标题框
            showEdit(type) {
                if (type === 1) {
                    this.createForm.portrayal_analyse.isEdit = !this.createForm.portrayal_analyse.isEdit
                }
                if (type === 2) {
                    this.createForm.account_analyse.isEdit = !this.createForm.account_analyse.isEdit
                }
                if (type === 3) {
                    this.createForm.selection_analyse.isEdit = !this.createForm.selection_analyse.isEdit
                }
                if (type === 4) {
                    this.createForm.video_analyse.isEdit = !this.createForm.video_analyse.isEdit
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .dataAnalysis-container {
        position: relative;
        /*height: calc(100vh - 100px);*/
        display: flex;
        flex-direction: column;
        .dataAnalysis-wrapper {
            display: flex;
            /*height: 1%;*/
            /*flex: 1;*/
            flex-direction: column;
            background: #fff;
            margin-top: 20px;
            border-radius: 10px;
            padding: 30px 40px 0;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
            .tab-wrapper {
                .tab-item {
                    display: inline-block;
                    height: 32px;
                    padding: 0 22px;
                    line-height: 32px;
                    background: #F1F0FE;
                    color: #584EEE;
                    font-size: 16px;
                    margin-right: 20px;
                    border-radius: 4px;
                    transition: all .3s;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.current, &:hover {
                        color: #fff;
                        background: #574DED;
                    }
                }
            }
        }
    }
    .form-custom {
        .el-form-item {
            ::v-deep .el-form-item__label {
                width: 100%;
            }
        }
        .form-item-title {
            line-height: 41px;
            .label-text {
                margin-right: 10px;
            }
            .iconfont {
                cursor: pointer;
            }
        }
    }
</style>